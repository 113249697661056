import React from 'react';
import './EndText.css'; // Assuming you have a CSS file for styling

const EndText = () => {
  return (
    <div>
      <div className='underline'></div>
      <div className='endLine'>
        <p>All rights reserved to polyporaits</p>
      </div>
    </div>
  );
}

export default EndText;
