import React from 'react';
import './floatingButtons.css'; // Import your CSS file for styling
import { FaInstagram, FaFacebook, FaWhatsapp } from 'react-icons/fa'; // Import icons from Font Awesome

const FloatingButtons = () => {
    return (
        <div className="sticky-container">
            <a href="https://instagram.com" className="sticky-button instagram" target="_blank" rel="noopener noreferrer">
                <FaInstagram style={{marginTop:13}}/>
            </a>
            <a href="https://facebook.com" className="sticky-button facebook" target="_blank" rel="noopener noreferrer">
                <FaFacebook style={{marginTop:13}}/>
            </a>
            <a href="https://wa.me/1234567890" className="sticky-button whatsapp" target="_blank" rel="noopener noreferrer">
                <FaWhatsapp style={{marginTop:13}} />
            </a>
        </div>
    );
};

export default FloatingButtons;
