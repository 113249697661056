import React from 'react'

function Contact() {
  return (
    <div>
      <h1 style={{color:'white'}}>mukta datta</h1>
    </div>
  )
}

export default Contact
