import React from 'react';

import './BayNow.css';

const BayNow = () => {
  const image1 = require('../swiperImages/baynow2.png')
  const image2 = require('../swiperImages/baynow.png')

  return (
    <div className="container">
     
      <div className="images">
        <img src={image1} alt="Product display 1" className="image" />
        <div className="text">𝙏𝙃𝙀 𝘿𝙐𝘼𝙇𝙄𝙏𝙔 𝙄𝙎 𝙐𝙉𝙈𝘼𝙏𝘾𝙃𝙀𝘿</div>
        <img src={image2} alt="Product display 2" className="image" />
      </div>
      <div className="footer">
      
          <button className="buy-button">𝐁𝐮𝐲 𝐍𝐨𝐰</button>
        
      </div>
    </div>
  );
};

export default BayNow;
