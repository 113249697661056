import React from 'react';
import './Services.css';

const Services = () => {
  return (
    <div className='container'>
      
      {/* OUR_SURVICE */}
      <div className='our_service_title'>
        <p className='our_service_text'>OUR SERVICES :</p>
      </div>

       {/* POLYPORTRAITS */}
      <div className='poly_portraits'>
        <div className='poly_portraits_text1'>
          <p>𝖯𝗈𝗅𝗒𝗉𝗈𝗋𝗍𝗋𝖺𝗂𝗍𝗌: 𝖸𝗈𝗎𝗋 𝖣𝖾𝗌𝗍𝗂𝗇𝖺𝗍𝗂𝗈𝗇 𝖿𝗈𝗋 𝖧𝗂𝗀𝗁-𝖰𝗎𝖺𝗅𝗂𝗍𝗒 3𝖣 𝖯𝗋𝗂𝗇𝗍𝖺𝖻𝗅𝖾 𝖡𝗎𝗌𝗍𝗌</p>
        </div>

        <div className='poly_portraits_text2'>
          <p>At Polyportraits, we specialize in creating high-quality 3D printable busts
            that capture the essence of your most cherished memories. We transform your photos into
            detailed, lifelike 3D portraits, perfect for gifts, awards, or personal collections.
            We ensure exceptional detail and quality in every piece.</p>
        </div>
      </div>
     
        {/* QUESTION */}
      <div className='question'>
        <div className='question_text'>
          <p>Why Choose Polyportraits?</p>
        </div>

        <div className='answer_text'>
          <p>At Polyportraits, we transform your cherished memories into stunning,
            lifelike 3D printable busts that stand out for their unparalleled quality
            and artistic craftsmanship. Our talented team of artists and technicians
            utilize cutting-edge 3D printing technology to capture every detail, ensuring
            each piece is a true work of art. We offer bespoke services to create personalized
            sculptures that reflect the unique essence of the individual, perfect for commemorating
            special moments or gifting to loved ones. Whether you prefer a digital download ready for
            your own 3D printer or a meticulously crafted physical bust in a choice of premium materials
            and finishes, we have the perfect solution for you. Our commitment to excellence, innovation,
            and customer satisfaction ensures a seamless and delightful experience from start to finish.
            Choose Polyportraits and let us turn your memories into beautifully sculpted masterpieces that
            will be treasured for a lifetime. Discover the art of 3D printing with Polyportraits,
            where your memories are elegantly brought to life.
          </p>
        </div>
      </div>

        {/* CONTACT_US */}
      <div className='contact_us'>
        <p className='contact_us_text'>
          Contact us today to start your journey with Polyportraits - where art meets precision.
        </p>
      </div>
    </div>
  );
}

export default Services;
