import React from 'react';
import './threeImage.css';

const ThreeImage = () => {

    const image1 = require('../swiperImages/3img_1.png')
    const image2 = require('../swiperImages/3img_2.png')
    const image3 = require('../swiperImages/3img_3.png')
  return (
    <div className="container">
     
      <div className="image-row">
        <div className="image-container">
        <img src={image1} alt="Product display 1" className="image" />
        </div>
        <div className="image-container">
        <img src={image2} alt="Product display 2" className="image" />
        </div>
        <div className="image-container">
        <img src={image3} alt="Product display 3" className="image" />
        </div>
      </div>
    </div>
  );
}

export default ThreeImage;
