import React from 'react';
import './customModels.css';

const CustomModels = () => {
  return (
    <div className="custom-models-container">
      <h2>CUSTOM MODELS</h2>
      <div className="content">
        <p>
          At Polyportraits, we meticulously craft custom 3D models to meet your unique needs.
          Whether you desire a full-body sculpture, a lifelike model of your beloved pet, or any
          other specific design, our expert artists are here to bring your vision to life.
        </p>
        <p>
          To receive a personalized quote, simply leave us a message. We will get back to you as
          soon as possible to discuss your project in detail. For faster assistance, follow and
          message us on Facebook.
        </p>
        <p>
          All our models are specially designed and optimized for 3D printing, ensuring the highest
          quality and precision in every piece.
        </p>
        <p>
          Contact Polyportraits today and let us create a masterpiece just for you.
        </p>
      </div>
      <div className="button-container">
        <button className="quote-button">𝐆𝐞𝐭 𝐐𝐮𝐨𝐭𝐞</button>
      </div>
    </div>
  );
};

export default CustomModels;
