
import React from 'react';



import BayNow from '../assets/component/bayNow/BayNow';
import Navbar from '../assets/component/Header/Navbar';
import SwiperSlider from '../assets/component/swiperCopmonent/SwiperSlider';
import Services from '../assets/component/services/Serviecs';
import ThreeImage from '../assets/component/ThreeImage/ThreeImage';
import Reviews from '../assets/component/reviews/Reviews';
import Packages from '../assets/component/packages/Packages';
import CustomModels from '../assets/component/custom/CustomModels';
import EndText from '../assets/component/endText/EndText';
import FloatingButtons from '../assets/component/button/FloatingButtons';

const Home = () => {
  return (


    <div>
      <Navbar />
     <SwiperSlider />  
     <Services/>                                          
      <BayNow />
      <ThreeImage />
      <CustomModels />
      <Reviews />
      <Packages />
      <EndText/>
      <FloatingButtons/>
    </div>
  );
};

export default Home;