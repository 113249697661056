import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Contact from "./pages/Contact";

import Home from "./pages/Home";
import AboutUs from './pages/AboutUs';
import Purchase from './pages/Purchase';
import Manu from './pages/Manu';
const App = () => {
  return (
<Router>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/about-us" element={<AboutUs/>} />
        <Route path="/purchase" element={<Purchase/>} />
        <Route path="/menu" element={<Manu/>} />
        
      </Routes>
    </Router>
  );
};

export default App;