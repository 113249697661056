import React from 'react'

function Purchase() {
  return (
    <div>
      <h1 style={{color:'white'}}>salma apa thank you</h1>
    </div>
  )
}

export default Purchase
