import React, { useState } from 'react';
import './reviews.css';

const reviewsData = [
  {
    id: 1,
    name: 'JULKERNINE ROKEY',
    image: 'https://via.placeholder.com/150',
    rating: 5,
    text: 'POLYPORTRAITS IS THE BEST, NOW GO...',
  },
  {
    id: 2,
    name: 'UMMA SALMA',
    image: 'https://via.placeholder.com/150',
    rating: 5,
    text: 'I AINT GONNA WRITE LOREM IPSUM...',
  },
];

const Reviews = () => {
  const [reviews, setReviews] = useState(reviewsData);

  const handleStarClick = (reviewId, starIndex) => {
    const updatedReviews = reviews.map((review) =>
      review.id === reviewId ? { ...review, rating: starIndex + 1 } : review
    );
    setReviews(updatedReviews);
  };

  return (
    <div className="reviews-container">
      <h2>REVIEWS</h2>
      {reviews.map((review, index) => (
        <div
          key={review.id}
          className={`review ${index % 2 === 0 ? 'left' : 'right'}`}
        >
          <img src={review.image} alt={`${review.name}`} className="profile-image" />
          <div className="review-content">
            <div className="stars">
              {[...Array(5)].map((_, starIndex) => (
                <span
                  key={starIndex}
                  className={`star ${starIndex < review.rating ? 'filled' : ''}`}
                  onClick={() => handleStarClick(review.id, starIndex)}
                >
                  ★
                </span>
              ))}
            </div>
            <p>{review.text}</p>
          </div>


          
        </div>
      ))}
    </div>
  );
};

export default Reviews;