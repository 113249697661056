import React from 'react';
import './Packages.css';

const Packages = () => {
  return (
    <div>
      <div className="packages-header">
        <h1>Packages</h1>
      </div>
      <div className="packages-container">
        <div className="package">
          <div className="package-header">BASIC</div>
          <div className="package-body">
            <div className="feature"></div>
            <div className="feature"></div>
            <div className="feature"></div>
          </div>
        </div>
        <div className="package">
          <div className="package-header">STANDARD</div>
          <div className="package-body">
            <div className="feature"></div>
            <div className="feature"></div>
            <div className="feature"></div>
          </div>
        </div>
        <div className="package">
          <div className="package-header">PREMIUM</div>
          <div className="package-body">
            <div className="feature"></div>
            <div className="feature"></div>
            <div className="feature"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Packages;
