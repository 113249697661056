import React from 'react'
import headerLogo from '../swiperImages/headerLogo.png';
import { FiAlignCenter } from 'react-icons/fi';
import './Navbar.css'
import { Link, useNavigate } from 'react-router-dom';

const Navbar = () => {
  const navigate = useNavigate();
  const handleMenuClick = () => {
    navigate('/menu');
  };
  
  return (
    <header className="header" >

      {/* Logo+Text */}
      <div className='logo-container'>
        <img src={headerLogo} alt="Logo" className="logo" />
        <p className="logo-text">PolyPortraits</p>
      </div>


      {/* NavBar */}
      <nav className="navbar">
        <div className="navbar_link">
          <Link to="/contact">CONTACT</Link>
          <Link to="/about-us">ABOUT US</Link>
          <Link to="/purchase">PURCHASE</Link>
        </div>
      </nav>

      {/* Bar_Icon */}
      <div className="menubar_bgcolor">
      <a href="#menu" className="menubar-link">
      <FiAlignCenter className="icon" onClick={handleMenuClick}/>
        </a>
      </div>
    
    </header>
  )
}

export default Navbar